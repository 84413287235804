<template>
  <section>
    <van-nav-bar title="支付" left-arrow @click-left="backSpace" />
    <van-form @submit="submit">
      <van-field name="radio" label="需支付金额">
        <template #input> {{ form.price }}元 </template>
      </van-field>
      <van-field name="radio" label="选择支付类型">
        <template #input>
          <van-radio-group v-model="form.pay_type" direction="horizontal">
            <van-radio v-if="type == 1" name="1">微信</van-radio>
            <van-radio v-if="type == 2" name="2">微信</van-radio>
            <van-radio v-if="type == 1" name="3">支付宝</van-radio>
            <van-radio v-if="type == 2" name="4">支付宝</van-radio>
            <van-radio name="5">余额支付</van-radio>
            <van-radio name="6">对公转账</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
    <div v-show="alert" class="alert faj">
      <div class="qrcode" ref="qrCodeUrl"></div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import qs from "qs";
import { NavBar } from "vant";
Vue.use(NavBar);
import { Toast } from "vant";
Vue.use(Toast);
import { Uploader } from "vant";
Vue.use(Uploader);
import { createPay, queryWxOrderstatus } from "@/assets/api/api";
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      form: {
        price: "",
        product_id: "",
        pay_type: "",
      },
      alert: false,
      type: "",
    };
  },
  computed: {},
  methods: {
    submit() {
      if (this.form.pay_type == "") {
        Toast("请选择支付类型");
      } else {
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
          loadingType: "spinner",
        });
        createPay(qs.stringify(this.form)).then((res) => {
          Toast.clear();
          if (res.data.code == 0) {
            console.log(this.form.pay_type)
            if (this.form.pay_type == "1") {
              window.location.href = res.data.data;
            }else if (this.form.pay_type == "5") {
              Toast("提交成功");
              this.$router.replace("success");
            } else if (this.form.pay_type == "6") {
              Toast("提交成功");
              this.$router.replace("success");
            } else if (this.form.pay_type == "2") {
              this.alert = true;
              new QRCode(this.$refs.qrCodeUrl, {
                text: res.data.data,
                width: 150,
                height: 150,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
              });
              this.product_id = res.data.product_id;
              this.queryWxOrderstatus();
            } else {
              Toast("提交成功");
              let div = document.querySelector("#payDiv");
              if (!div) {
                div = document.createElement("div");
                div.id = "payDiv";
                document.body.appendChild(div);
              }
              div.innerHTML = res.data.data;
              document.querySelector("#payDiv>form").submit();
            }
          } else {
            Toast(res.data.message);
          }
        });
      }
    },
    queryWxOrderstatus() {
      queryWxOrderstatus(qs.stringify({ product_id: this.product_id })).then(
        (res) => {
          Toast.clear();
          if (res.data.code == 0) {
            if (res.data.data == 1) {
              this.setTime = setTimeout(() => {
                this.queryWxOrderstatus();
              }, 2000);
            } else {
              clearTimeout(this.setTime);
              this.$router.replace("success");
            }
          } else {
            Toast(res.data.message);
          }
        }
      );
    },
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  created() {},
  mounted() {
    if (this.isMobile()) {
      this.type = 1;
    } else {
      this.type = 2;
    }
    this.form.price = this.$route.query.price;
    this.form.product_id = this.$route.query.id;
  },
};
</script>
<style scoped>
.van-radio {
  margin: 5px;
}
.title {
  font-size: 14px;
  color: #333;
  padding: 10px 0;
}
.alert {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 5;
}
</style>
